const info = {
  CN: {
    // 中文翻译
    account: "个人信息",
    myFavorites: "我的收藏",
    myVehicle: "我的车辆",
    username: "用户昵称",
    email: "Email",
    wechat: "微信",
    phone: "电话号码",
    save: "保存",

    quickSignIn: "免密码登录",
    signInTip: "第一次登录的账号将自动注册成为用户",
    wxLogin: "微信账户登录",
    or: "其他方式登录",
    phoneNumber: "手机号",
    getVerificationCode: "发送验证码",
    verificationCode: "验证码",
    registerText: "注册账户或发布车辆信息，既代表您同意我们的",
    privacyText: "使用及隐私政策",
    login: "登录",

    hintUserName: "请输入用户昵称",
  },
  EN: {
    // 英文翻译
    account: "Account",
    myFavorites: "My Favorites",
    myVehicle: "My Vehicle",
    username: "Username",
    email: "Email",
    wechat: "Wechat",
    phone: "Phone",
    save: "Save",

    quickSignIn: "Quick sign in",
    signInTip: "The account you log in for the first time will be automatically registered as a user",
    wxLogin: "Continue With Wechat",
    or: "Or",
    phoneNumber: "Phone Number",
    getVerificationCode: "Get Verification Code",
    verificationCode: "Verification code",
    registerText: "By registering an account or posting vehicle information, you agree to our",
    privacyText: "Use and Privacy Policy",
    login: "Login",

    hintUserName: "Please enter a userName",
  },
};
export default info;
