import Navbar from "./navbar/index";
import Home from "./home/index";
import ContactUs from "./contactUs/index";
import Sell from "./sell/index";
import Buy from "./buy/index";
import Me from "./me/index";
const CN = {
  Language: {
    type: "中文",
  },
  Navbar: Navbar.CN,
  Home: Home.CN,
  ContactUs: ContactUs.CN,
  Sell: Sell.CN,
  Buy: Buy.CN,
  Me: Me.CN,
};
const EN = {
  Language: {
    type: "English",
  },
  Navbar: Navbar.EN,
  Home: Home.EN,
  ContactUs: ContactUs.EN,
  Sell: Sell.EN,
  Buy: Buy.EN,
  Me: Me.EN,
};
export default {
  CN,
  EN,
};
