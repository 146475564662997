import { ResultCodeEnum } from "@/constants";
import request from "@/util/request";
import requestJson from "@/util/requestJson";
const url = "v1/login/member/web";

export default {
  simulateLogin: (params) => {
    return requestJson.post(`${url}/login`, params);
  },
  smsLogin: (params) => {
    return requestJson.post(`${url}/sms/login`, params);
  },
  logout: () => {
    return request.post(`${url}/logout`);
  },
  verifyLogin: () => {
    return request.get(`${url}/verify_login`);
  },
  getWxWebLoginUrl: (params) => {
    return requestJson.post(`${url}/getWxWebLoginUrl`, params);
  },
  getLoginUser: () => {
    return request.get(`v1/member/myCenter/getLoginUser`);
  },
  update: (params) => {
    return requestJson.put(`v1/member/myCenter`, params);
  },
  loginCode: (params) => {
    return request.post(`${url}/wx/login/${params.code}`, params);
  },
};
