import Vue from "vue";
import VueRouter from "vue-router";

// 1.为了达到路由懒加载的效果, 引用组件需要使用以下方式
// const Other = () => import("./other/index/index.vue");
// 2.首屏需要加载的组件直接引入
// import Home from "./home/index/index.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/index",
    },
    {
      path: "/",
      component: () => import("./common/Home.vue"),
      meta: { title: "home" },
      children: [
        {
          path: "/index",
          component: () => import("@/component/index/index.vue"),
          meta: { title: "INFOCAR" },
        },
        {
          path: "/contact",
          component: () => import("@/component/contact/index.vue"),
          meta: { title: "INFOCAR" },
        },
        {
          path: "/merchant",
          component: () => import("@/component/merchant/index.vue"),
          meta: { title: "INFOCAR" },
        },
        {
          path: "/news",
          component: () => import("@/component/news/index.vue"),
          meta: { title: "INFOCAR" },
        },
        {
          path: "/newsDetail",
          component: () => import("@/component/news/detail/index.vue"),
          meta: { title: "INFOCAR" },
        },
        {
          path: "/sell",
          component: () => import("@/component/sell/index.vue"),
          meta: { title: "INFOCAR" },
        },
        {
          path: "/sellCars",
          component: () => import("@/component/sell/sellCars/index.vue"),
          meta: { title: "INFOCAR" },
        },
        {
          path: "/buy",
          component: () => import("@/component/buy/index.vue"),
          meta: { title: "INFOCAR" },
        },
        {
          path: "/carDetail",
          component: () => import("@/component/buy/detail/index.vue"),
          meta: { title: "INFOCAR" },
        },
        {
          path: "/me",
          component: () => import("@/component/me/index.vue"),
          meta: { title: "INFOCAR" },
        },
        {
          path: "/privacy",
          component: () => import("@/component/privacy/index.vue"),
          meta: { title: "INFOCAR" },
        },
        {
          path: "/download",
          component: () => import("@/component/download/index.vue"),
          meta: { title: "INFOCAR" },
        },
      ],
    },
  ],
});

router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  if (to.meta.content) {
    const head = document.getElementsByTagName("head");
    const meta = document.createElement("meta");
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
