import request from "@/util/request";
import requestJson from "@/util/requestJson";
export default {
  // 字典值列表
  dictValue(params) {
    return request.get(`v1/base/dict/dictValue`, { pageNum: 0, pageSize: 100, ...params });
  },
  listChildren(params) {
    return request.get(`v1/base/mdDistricts/listChildren`, params);
  },
  mdConfigures(params) {
    return request.get(`v1/base/mdConfigures`, params);
  },
  send(params) {
    return requestJson.post(`v1/base/sms/send`, params);
  },
};
