const info = {
  CN: {
    // 中文翻译
    sellMyCar: "转车卖车",
    free: "免费发布车辆广告：转LEASE、私卖二手车、摩托车",
    highestPrice: "卖出自己想要的高价",
    time: "多种加速卖车方案，最快7天交易",
    sellCars: "发布汽车",
    sellMotorcycles: "发布摩托车",

    evaluate: "快速估价",
    cash: "全款",
    getMyOffer: "快速估价",
    appraise: "先估价，再卖车",
    deal: "价格合适当天即可成交",

    basicInformation: "基本信息",
    contactInformation: "联系方式",
    otherInformation: "额外信息",
    vehicleUpload: "车辆上线",

    fillInTheBasicInformation: "基本信息填写",
    vehicleModel: "车辆类型",
    cashCar: "全款车",
    lease: "转LEASE车辆",
    finance: "Finance车辆",
    newCar: "新车",
    reasonOfSell: "卖车原因",
    returnHomeland: "回国急转",
    boughtANewCar: "喜提新车",
    planToExchange: "计划换车",
    idleReplacement: "闲置置换",
    expectToConcludeTheDeal: "希望成交时间",
    inTheNextTwoWeeks: "未来两周内",
    withinOneMonth: "一个月内",
    aroundThreeMonths: "三个月左右",
    asSoonAsPossible: "尽快交车",

    vehilceInformationFillIn: "车辆信息填写",
    vehicleYear: "车辆年份",
    vehicleBrand: "车辆品牌",
    currentKilometers: "现公里数",
    originalDownPayment: "原首付",
    expectingPrice: "理想价位",
    remainingKilometers: "剩余公里数",
    province: "车辆所在位置",
    city: "车辆所在城市",

    pleaseSelect: "请选择",
    pleaseInput: "请输入",

    buyout: "现买断价 (现在还欠银行多少钱)",
    expectingToGet: "要回首付",
    rebateSubsidy: "返现补贴",
    monthlyPaymentAfterTax: "税后月供",
    moneyTwoWeeksAfterTax: "税后双周",
    monthsRemaining: "剩余月数",
    preTaxBuyout: "税前到期买断",

    howTouch: "买家如何与您取得联系",
    username: "用户昵称",
    phone: "电话",
    wechat: "微信号",
    email: "Email",
    uploadTheContract: "上传车辆合同",
    optional: "(选填)",
    uploadTip: "合同信息有助于我们做出准确的估价，合同不会对外公开",
    uploadTheCarfaxLink: "上传Carfax链接",
    support: "请上传正确的文件格式，支持JPG,JPEG,PNG",
    upload: "上传文件",
    uploadVehiclePicture: "上传车辆照片",
    uploadVehiclePictureTip: "首图为封面图，每张图片请勿超过5MB大小",

    fillInMoreInformation: "更多信息填写",
    originalCarColor: "原厂车身颜色",
    anyAccident: "有无事故",
    anySratched: "有无剐蹭",
    yes: "有",
    no: "无",
    gearbox: "变速箱",
    automatic: "自动",
    manual: "手动",
    driveMode: "驱动模式",
    adw: "四驱",
    fdw: "前驱",
    rdw: "后驱",
    vehicleName: "希望显示车辆名称",

    vehicleConfiguration: "车辆配置",
    configurationTip: "请尽可能填写详细的车辆配置，以便吸引更多的买家获得更多的曝光",
    parkingRadarVideo: "倒车雷达影像",
    securityConfigurationOptions: "安全配置选项",
    audioConfiguration: "音响配置",
    performanceOptions: "性能选项",
    otherConfiguration: "其他配置",
    moreConfigurationAdditions: "更多配置补充",
    moreConfigurationTip: "填写车辆配置包",

    next: "下一步",
    back: "上一步",
    publish: "发布",
    contactServe: "联系客服",

    successTitle: "您的车辆信息已保存并提交审核",
    successTip: "您的车辆正在审核中，请添加客服微信完成审核并发布车辆",
    ownerCarTitle: "我是车主，下一步做什么？",
    ownerCarTip: "添加客服微信，配合审核并完成发布。若审核中出现任何问题，好及时与您取得联系。",
    auditTime: "预计审核时间：1个工作日内",

    evaluateTitle: "估价申请提交成功！",
    evaluateSubTitle: "我们会根据您的信息，结合市场上目前在售的相似车辆进行价格分析,并在24 - 72小时内给您报价",
    evaluateOwnerCarTip: "添加客服微信，随时查询车辆估价情况。若市场价格有波动，好及时与您取得联系。",
    evaluateAuditTime: "预计报价时间：24 - 72小时",

    hintUploadVehiclePhoto: "请上传车辆照片",

    question: "常见问题",
  },
  EN: {
    // 英文翻译
    sellMyCar: "Sell My Car",
    free: "Post the ad for free: Lease Transfer or Pre-Owned",
    highestPrice: "Sell at the highest price you want.",
    time: "Feature your car and get it sold within 7 days",
    sellCars: "Sell Cars",
    sellMotorcycles: "Sell Motorcycles",

    evaluate: "Instant Cash Offer",
    cash: "Cash",
    getMyOffer: "Get My Offer",
    appraise: "Appraise the car before you sell it",
    deal: "The deal can be made on the day the price is right",

    basicInformation: "Basic information",
    contactInformation: "Contact information",
    otherInformation: "Other information",
    vehicleUpload: "Vehicle upload",
    fillInTheBasicInformation: "Fill in the basic information",

    vehicleModel: "Vehicle model",
    cashCar: "Cash",
    lease: "Lease",
    finance: "Finance",
    newCar: "New",
    reasonOfSell: "Reason of sell",
    returnHomeland: "Return homeland",
    boughtANewCar: "Bought a new car",
    idleReplacement: "Idle Replacement",
    planToExchange: "Plan to exchange",
    expectToConcludeTheDeal: "Expect to conclude the deal",
    inTheNextTwoWeeks: "In the next two weeks",
    withinOneMonth: "Within one month",
    aroundThreeMonths: "Around three months",
    asSoonAsPossible: "As soon as possible",

    vehilceInformationFillIn: " vehilce information fill in",
    vehicleYear: "Vehicle year",
    vehicleBrand: "Vehicle brand",
    currentKilometers: "Current kilometers",
    originalDownPayment: "Original down payment",
    expectingPrice: "Expecting price",
    remainingKilometers: "Remaining kilometers",
    province: "Province",
    city: "City",
    pleaseSelect: "Please select",
    pleaseInput: "Please input",
    buyout: "Buyout (How much still owe the bank)",
    expectingToGet: "Expecting to get (down payment)",
    rebateSubsidy: "Rebate subsidy",
    monthlyPaymentAfterTax: "Monthly payment after tax",
    moneyTwoWeeksAfterTax: "Two weeks after tax",
    monthsRemaining: "Months remaining",
    preTaxBuyout: "Pre-tax buyout",

    howTouch: "How buyers can get in touch with you",
    username: "Username",
    phone: "Phone",
    wechat: "Wechat",
    email: "Email",
    uploadTheContract: "Upload the contract",
    optional: "Optional",
    uploadTip: "Contract information helps us make accurate estimates",
    uploadTheCarfaxLink: "Upload the Carfax link",
    support: "Please upload the correct file format Support JPG, JPEG, PNG",
    upload: "Upload",
    uploadVehiclePicture: "Upload vehicle picture",
    uploadVehiclePictureTip: "The first picture is the cover picture, each picture size should not exceed 5MB",

    fillInMoreInformation: "Fill in more information",
    originalCarColor: "Original car color",
    anyAccident: "Any accident",
    anySratched: "Any scratched",
    yes: "Yes",
    no: "No",
    gearbox: "Gearbox",
    automatic: "Automatic",
    manual: "Manual",
    driveMode: "Drive mode",
    adw: "AWD",
    fdw: "FWD",
    rdw: "RWD",
    vehicleName: "Vehicle's name",

    vehicleConfiguration: "Vehicle configuration",
    configurationTip: "Please fill in the detailed vehicle configuration as much as possible to attract more buyers",
    parkingRadarVideo: "Parking radar video",
    securityConfigurationOptions: "Security configuration options",
    audioConfiguration: "Audio configuration",
    performanceOptions: "Performance options",
    otherConfiguration: "Other configuration",
    moreConfigurationAdditions: "More configuration additions",
    moreConfigurationTip: "Fill out the vehicle configuration package",

    next: "Next",
    back: "Back",
    publish: "Publish",
    contactServe: "Contact Information",

    successTitle: "Your vehicle information has been saved and submitted for review",
    successTip:
      "Your vehicle is under review, please add customer service WeChat to complete the reveiew and release the vehicle",
    ownerCarTitle: "I am the owner of the car, what should I do next?",
    ownerCarTip:
      "Add customer service WeChat, cooperate with the review and complete the release. If there are any problem during the review, we can contact you on time.",
    auditTime: "Estimated review time: within 1 working business day",

    evaluateTitle: "Valuation application submitted successfully!",
    evaluateSubTitle:
      "Based on your information, we will analyze the price of similar vehicles currently available on the market and give you a quote within 24-72 hours",
    evaluateOwnerCarTip:
      "Add customer service wechat to check the vehicle valuation at any time. If the market price fluctuates, it is good to contact you in time.",
    evaluateAuditTime: "Estimated quotation time: 24-72 hours",

    hintUploadVehiclePhoto: "Please upload a photo of the vehicle",

    question: "Common questions",
  },
};
export default info;
