const info = {
  CN: {
    // 中文翻译
    filter: "基本筛选菜单",
    buyingOptions: "购买方式",
    leaseAndFinance: "LEASE/分期车辆",
    cash: "全款二手车源",
    private: "私人来源",
    dealer: "车行车源",
    // carsAndMotorcycles: "车辆类型",
    // cars: "汽车",
    // motorcycles: "摩托车",
    makeAndModel: "品牌及车型",
    price: "全款车价",
    aging: "分期首付月供",
    yearAndMileage: "年份及公里数",
    bodyStyle: "车辆分类",
    drivetrain: "驱动模式",
    transmission: "变速箱选择",

    brands: "品牌",

    // 顶部导航栏
    all: "全部",
    leaseTransfers: "转LEASE",
    shopNew: "4S店新车",
    fullyPaid: "私人全款车",
    lowMileage: "低公里数",
    within: "5万预算内",
    shortTermLease: "一年左右短期LEASE",
    downPayment_w: "一万首付搞定",

    showing: "正在显示",
    available: "在售车辆",
    clearFilters: "全部清除",
    financeTransfer: "转FINANCE",
    cashTransfer: "全款",
    showMore: "加载更多",
    noData: "暂无数据",
    month: "月",
    down: "首付",
    monthPay: "月供",
    estTotal: "剩余总价",
    listedPrice: "全款",
    mileage: "公里数",
    carPrice: "车价",
    term: "到期时间",
    theMo: "个月",
    year: "年份",
    model: "车型",
    multiple: "品牌多选",
    single: "品牌及具体车型",
    clear: "清空选择",
    search: "搜索",
    leaseFinance: "分期首付月供",
    downPayment: "首付",
    monthlyPayment: "月供",
    termTime: "贷款时长/到期时间",

    noMore: "没有更多了",
    unavailable: "已下架",

    view: "已浏览",
    address: "地址",
    contact: "联系接车",
    like: "收藏",
    classification: "分类",
    inspection: "车辆检查",
    loan: "付款审批",
    insurance: "上险提车",
    vehicleDetails: "车辆详情",
    features: "配置详情",

    termsAndPayments: "*此方案为固定方案，无法更改",
    originalCarColor: "原厂车身颜色",
    transmissionText: "变速箱",
    accidentHistory: "事故记录",
    manual: "手动",
    automatic: "自动",
    yes: "有",
    no: "无",
    adw: "四驱",
    fdw: "前驱",
    rdw: "后驱",
    scratches: "剐蹭情况",
    moreConfigurationAdditions: "车主补充",

    contactTip: "请填写/确认您的联系方式, 我们会尽快让销售与您对接",
    name: "姓名或称呼",
    telephone: "联系电话",
    email: "Email(选填)",
    submit: "提交",
  },
  EN: {
    // 英文翻译
    // 左边导航栏
    filter: "Filter",
    buyingOptions: "Buying Options",
    leaseAndFinance: "Lease/Finance",
    cash: "Cash",
    private: "Private",
    dealer: "Dealer",
    carsAndMotorcycles: "Cars / Motorcycles",
    cars: "Cars",
    motorcycles: "Motorcycles",
    makeAndModel: "Make and Model",
    price: "Price",
    aging: "Lease/Finance",
    yearAndMileage: "Year and Mileage",
    bodyStyle: "Body Style",
    drivetrain: "Drivetrain",
    transmission: "Transmission",

    // 顶部导航栏
    all: "All",
    leaseTransfers: "Lease Transfers",
    shopNew: "Shop New",
    fullyPaid: "Fully Paid",
    lowMileage: "Low Mileage",
    within: "Within $50,000",
    shortTermLease: "Short Term Lease",
    downPayment_w: "$10,000 Down Payment",
    showing: "Showing",
    available: "available",

    clearFilters: "Clear filters",
    brands: "Brands",
    financeTransfer: "FINANCE Transfer",
    cashTransfer: "Cash",
    showMore: "Show More",
    noData: "No Data",
    month: "mo.",
    down: "Down",
    monthPay: "", // 月供英文不显示
    estTotal: "Est.Total",
    listedPrice: "Listed Price",
    mileage: "Mileage",
    carPrice: "Price",
    term: "Term",
    theMo: "mo.",
    year: "Year",
    model: "Model",
    multiple: "Multiple",
    single: "Single",
    clear: "Clear",
    search: "Search",
    leaseFinance: "Lease/Finance",
    downPayment: "Down Payment",
    monthlyPayment: "Monthly Payment",
    termTime: "Term",

    noMore: "No More",
    unavailable: "Unavailable",

    view: "View",
    address: "Address",
    contact: "Contact",
    like: "Like",
    classification: "Classification",
    inspection: "Inspection",
    loan: "Loan",
    insurance: "Insurance",
    vehicleDetails: "VehicleDetails",
    features: "Features",
    termsAndPayments: "*Taxes, Freight & PDI Included. Terms and payments are fixed.",
    originalCarColor: "Original car color",
    transmissionText: "Transmission",
    accidentHistory: "Accident",
    manual: "Manual",
    automatic: "Automatic",
    yes: "Yes",
    no: "No",
    adw: "AWD",
    fdw: "FWD",
    rdw: "RWD",
    scratches: "Scratches",
    moreConfigurationAdditions: "More configuration additions",

    contactTip: "Please fill in your contact info. We will contact you shortly.",
    name: "name",
    telephone: "telephone",
    email: "Email",
    submit: "Submit",
  },
};
export default info;
