const info = {
  CN: {
    // 中文翻译
    homeText: "主页",
    buy: "我要买车",
    sell: "我要卖车",
    sellCars: " - 发布汽车",
    sellMotorcycle: " - 发布摩托车",
    information: "购车资讯",
    contactUs: "联系我们",
    contactUsTitle: "联系方式",
    phone: "电话：",
    wechat: "微信：",
    email: "邮箱：",
    address: "地址：",
    // addressTwo: "Toronto, ON M2J 1R3",
    aboutUs: "关于我们",
    termsofUse: "使用条款和政策",
    workingHours: "工作时间",
    weekdays: "周一至周五       12:00 - 18:00",
    weekends: "周六及周天       休息",
  },
  EN: {
    // 英文翻译
    homeText: "Home",
    buy: "Buy",
    sell: "Sell",
    sellCars: " - Sell Car",
    sellMotorcycle: " - Sell Motorcycle",
    information: "News",
    contactUs: "Contact Us",
    contactUsTitle: "Contact Us",
    phone: "Phone：",
    wechat: "Wechat：",
    email: "Email：",
    address: "Address：",
    // addressTwo: "Toronto, ON M2J 1R3",
    aboutUs: "About Us",
    termsofUse: " Terms of Use",
    workingHours: "Working Hours",
    weekdays: "Weekdays       12:00 - 18:00",
    weekends: "Weekends       Closed",
  },
};
export default info;
