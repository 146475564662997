<template>
  <a-modal
    v-model="active"
    :title="localeLanguage === 'cn' ? '免密码登录' : 'Quick sign in'"
    :footer="null"
    @cancel="handleCancel"
  >
    <div class="modalContent">
      <div class="tip">
        {{
          localeLanguage === "cn"
            ? "第一次登录的账号将自动注册成为用户"
            : "The account you log in for the first time will be automatically registered as a user"
        }}
      </div>
      <div class="flex flex-center">
        <div class="wechat-btn flex flex-middle flex-center" @click="getWxWebLoginUrl">
          <img src="@/assets/images/icon_wechat.png" alt="" />
          <div>{{ localeLanguage === "cn" ? "微信账户登录" : "Continue With Wechat" }}</div>
        </div>
      </div>
      <div class="or-signup">{{ localeLanguage === "cn" ? "其他方式登录" : "Or" }}</div>
      <div class="passwordLogin">
        <div class="inputItem">
          <div class="title">{{ localeLanguage === "cn" ? "手机号" : "Phone Number" }}</div>
          <a-input :value="phone" class="loginInput" addon-before="+ 1" placeholder="" size="large" @change="onChange">
            <div slot="suffix" class="sendCode" @click="countDown">
              {{ codeName }}
            </div>
          </a-input>
        </div>
        <div class="inputItem">
          <div class="title">{{ localeLanguage === "cn" ? "验证码" : "Verification code" }}</div>
          <a-input :value="code" type class="loginInput" size="large" @change="onChangeCode"> </a-input>
        </div>
      </div>
      <div class="policy flex flex-top">
        <a-icon type="check-circle" class="iconCircle" />
        <div>
          {{
            localeLanguage === "cn"
              ? "注册账户或发布车辆信息，既代表您同意我们的"
              : "By registering an account or posting vehicle information, you agree to our"
          }}
          <span class="blueText" @click="toLink('/privacy')"
            >「{{ localeLanguage === "cn" ? "使用及隐私政策" : "Use and Privacy Policy" }}」</span
          >.
        </div>
      </div>
      <div class="comfirmBtn" @click="submit">
        {{ localeLanguage === "cn" ? "登录" : "Login" }}
      </div>
    </div>
  </a-modal>
</template>

<script>
import Vue from "vue";
import antd from "ant-design-vue";
import baseApi from "@/api/baseApi";
import { ResultCodeEnum } from "@/constants";
import Utils from "@/util/util";
import loginApi from "@/api/loginApi";
import router from "@/component/route";
Vue.use(antd);
export default {
  data() {
    return {
      active: false,
      phone: "",
      code: "",
      deadline: 60,
      isSend: false,
      codeName: localStorage.getItem("languageSet") === "EN" ? "Get Verification Code" : "发送验证码",
      totalTime: 60,
      timer: "", // 定时器

      localeLanguage: localStorage.getItem("languageSet") === "EN" ? "en" : "cn",
    };
  },

  created: function() {
    // this.codeName = this.$t("Me.Me.getVerificationCode");
  },

  mounted: function() {},

  methods: {
    countDown() {
      if (this.isSend) return;
      this.getCode(); // 获取验证码的接口
    },
    onChange(e) {
      const { value } = e.target;
      const reg = /^-?[0-9]*(\.[0-9]*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
        this.phone = value;
      }
    },
    onChangeCode(e) {
      const { value } = e.target;
      const reg = /^-?[0-9]*(\.[0-9]*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
        this.code = value;
      }
    },
    open() {
      this.active = true;
    },
    close() {
      this.active = false;
    },
    handleOk(e) {
      this.confirmLoading = true;
      setTimeout(() => {
        this.active = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel(e) {
      this.active = false;
    },
    async getCode() {
      if (!this.phone) {
        this.$message.warning(this.localeLanguage === "cn" ? "请输入手机号码" : "Please enter your mobile number");
        return;
      }
      const param = {
        countryCode: "CA",
        phone: this.phone,
        type: 2,
      };
      const loading = this.$loading({
        text: "",
      });
      const res = await baseApi.send(param);
      loading.close();
      if (res.code === ResultCodeEnum.success.code) {
        this.$message.success("Success");
        this.isSend = true;
        this.codeName = this.totalTime + "s";
        this.timer = setInterval(() => {
          this.totalTime--;
          this.codeName = this.totalTime + "s";
          if (this.totalTime < 0) {
            clearInterval(this.timer);
            this.codeName = this.localeLanguage === "cn" ? "发送验证码" : "Get Verification Code";
            this.totalTime = 60;
            this.isSend = false;
          }
        }, 1000);
      } else {
        // 请求失败
      }
    },
    async getWxWebLoginUrl() {
      console.log(window.location.href);
      const res = await loginApi.getWxWebLoginUrl({ redirectUri: window.location.href });
      if (res.code === ResultCodeEnum.success.code) {
        window.location.href = res.data;
      } else {
        // 请求失败
      }
    },
    async submit() {
      if (!this.phone) {
        this.$message.warning(this.localeLanguage === "cn" ? "请输入手机号码" : "Please enter your mobile number");
        return;
      }
      if (!this.code) {
        this.$message.warning(this.localeLanguage === "cn" ? "请输入验证码" : "Please enter the verification code");
        return;
      }
      const param = {
        countryCode: "CA",
        phone: this.phone,
        smsCode: this.code,
      };
      const res = await loginApi.smsLogin(param);
      if (res.code === ResultCodeEnum.success.code) {
        this.$message.success("Success");
        localStorage.setItem("loginToken", "success");
        this.active = false;
        location.reload();
        // todo
      } else {
        // 请求失败
      }
    },

    toLink(link) {
      this.active = false;
      router.push(link);
    },
  },
};
</script>

<style lang="less" scoped>
// @import "./index.less";
.wechat-btn {
  border: 1px solid #d1d1d1;
  border-radius: 12px;
  width: 100%;
  padding: 5px;
  text-align: center;
  height: 40px;
  cursor: pointer;
}
.sendCode {
  color: #1a72fe;
  cursor: pointer;
}
.tip {
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #a9a9a9;
  flex-direction: column;
  margin-bottom: 15px;
  width: 330px;
  font-weight: lighter;
}
.passwordLogin {
  .inputItem {
    margin-bottom: 10px;
    .title {
      margin-bottom: 5px;
    }
  }
}
.or-signup {
  max-width: 500px;
  text-align: center;
  margin: auto;
  position: relative;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #a9a9a9;
  font-weight: 300;
  margin: 20px 0;
}
.modalContent {
  padding: 0px 50px;
}
.modalContent .or-signup::before {
  content: "";
  width: 100px;
  height: 0.83px;
  display: flexbox;
  background: #a9a9a9;
  position: absolute;
  top: 50%;
  left: 12.9%;
  opacity: 0.65;
}
.modalContent .or-signup::after {
  content: "";
  content: "";
  width: 100px;
  height: 0.83px;
  display: flexbox;
  background: #a9a9a9;
  position: absolute;
  top: 50%;
  right: 12.9%;
  opacity: 0.65;
}

.policy {
  font-size: 12px;
  color: #a9a9a9;
  text-align: left;
  margin-left: 10px;
  margin-right: 45px;
  margin-top: -7px;
  margin-top: 20px;
  margin-bottom: 20px;
  .iconCircle {
    color: #1a72fe;
    margin-right: 5px;
    font-size: 20px;
  }
}
.comfirmBtn {
  border: 2px solid transparent;
  background-color: #1a72fe;
  border-radius: 12px;
  width: 100%;
  padding: 5px;
  text-align: center;
  height: 40px;
  color: #fff;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-bottom: 30px;
}
.blueText {
  color: #1a72fe;
  cursor: pointer;
}
</style>
<style></style>
