const info = {
  CN: {
    // 中文翻译
    buyCars: "我要买车",
    sellTrade: "我要卖车",
    search: "搜索",
    searchPlaceHolder: "请输入关键词，例如BMW",
  },
  EN: {
    // 英文翻译
    buyCars: "Buy Cars",
    sellTrade: "Sell/Trade",
    search: "Search",
    searchPlaceHolder: "Please enter a keyword, such as BMW",
  },
};
export default info;
