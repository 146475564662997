import md5 from "md5";

export default {
  md5(str) {
    return md5(str);
  },

  formatDate: function(date, formatStr) {
    if (!date) {
      return "";
    }
    date = new Date(date);
    function $addZero(v, size) {
      for (let i = 0, len = size - (v + "").length; i < len; i++) {
        v = "0" + v;
      }
      return v + "";
    }

    // 格式化时间
    const arrWeek = ["日", "一", "二", "三", "四", "五", "六"],
      str = formatStr
        .replace(/yyyy|YYYY/, date.getFullYear())
        .replace(/yy|YY/, $addZero(date.getFullYear() % 100, 2))
        .replace(/mm|MM/, $addZero(date.getMonth() + 1, 2))
        .replace(/m|M/g, date.getMonth() + 1)
        .replace(/dd|DD/, $addZero(date.getDate(), 2))
        .replace(/d|D/g, date.getDate())
        .replace(/hh|HH/, $addZero(date.getHours(), 2))
        .replace(/h|H/g, date.getHours())
        .replace(/ii|II/, $addZero(date.getMinutes(), 2))
        .replace(/i|I/g, date.getMinutes())
        .replace(/ss|SS/, $addZero(date.getSeconds(), 2))
        .replace(/s|S/g, date.getSeconds())
        .replace(/w/g, date.getDay())
        .replace(/W/g, arrWeek[date.getDay()]);
    return str;
  },
  transformTreeData: function(flatArrs, parentItem) {
    const children = flatArrs
      .filter((item, index) => {
        return item.parentId === parentItem.id;
      })
      .map((record) => {
        record.label = record.name;
        record.value = record.id;
        record.key = record.id;
        return record;
      });
    parentItem.children = children;
    for (const i in children) {
      this.transformTreeData(flatArrs, children[i]);
    }
    return parentItem.children;
  },
  transformCascaderData: function(flatArrs, parentItem) {
    const children = flatArrs
      .filter((item, index) => {
        return item.parentId === parentItem.id;
      })
      .map((record) => {
        record.label = record.name;
        record.value = record.id;
        record.key = record.id;
        return record;
      });
    if (children.length !== 0) {
      parentItem.children = children;
    }
    for (const i in children) {
      this.transformCascaderData(flatArrs, children[i]);
    }
    return parentItem.children;
  },
  isArray: function(o) {
    return Object.prototype.toString.call(o) === "[object Array]";
  },
  moneyMinuteToYuan: function(dollar) {
    if (!isNaN(dollar)) {
      dollar = (dollar * 0.01).toFixed(2); // 分到元
      // dollar += '';//转成字符串
      // var reg = dollar.indexOf('.') > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;//千分符的正则
      // dollar = dollar.replace(reg, '$1,');//千分位格式化
      // var str = (dollar/100).toFixed(2) + '';
      // var intSum = str.substring(0,str.indexOf(".")).replace( /\B(?=(?:\d{3})+$)/g, ',' );//取到整数部分
      // var dot = str.substring(str.length,str.indexOf("."))//取到小数部分
      // var ret = intSum + dot;
      return dollar;
    }
  },
  // html转义
  HTMLEncode: function(html) {
    let temp = document.createElement("div");
    temp.textContent !== null ? (temp.textContent = html) : (temp.innerText = html);
    const output = temp.innerHTML;
    temp = null;
    return output;
  },
  // html反转义
  HTMLDecode: function(text) {
    let temp = document.createElement("div");
    temp.innerHTML = text;
    const output = temp.innerText || temp.textContent;
    temp = null;
    return output;
  },
  filterNull: function(obj) {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== "") {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  },
};
