import Vue from "vue";
import VueWechatTitle from "vue-wechat-title";
import router from "@/component/route";
import App from "@/component/App.vue";
import "./element";
import "@/component/LoginDialog/LoginDialog"; // 登录弹窗全局组件
import {
  FormModel,
  Input,
  Button,
  Row,
  Col,
  Radio,
  Menu,
  Carousel,
  Icon,
  Upload,
  Checkbox,
  Select,
  Spin,
  Modal,
  Descriptions,
  Table,
  InputNumber,
  Statistic,
  Dropdown,
  Slider,
  Empty,
  Tabs,
  Result,
} from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import BaiduMap from "vue-baidu-map";
import VueI18n from "vue-i18n";
// 中英文切换
import LanguageData from "@/language/index";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
Vue.use(Viewer);
// 中文最终汇总暴露的信息
Vue.config.productionTip = false;
Vue.use(VueWechatTitle);
Vue.use(BaiduMap, { ak: "vLYLLEZAgLAp1X5MoCULEs9bsod5OdOO" });
Vue.use(VueI18n);
Vue.use(FormModel);
Vue.use(Input);
Vue.use(Button);
Vue.use(Row);
Vue.use(Col);
Vue.use(Radio);
Vue.use(Menu);
Vue.use(Carousel);
Vue.use(Icon);
Vue.use(Upload);
Vue.use(Checkbox);
Vue.use(Select);
Vue.use(Spin);
Vue.use(Modal);
Vue.use(Descriptions);
Vue.use(Table);
Vue.use(InputNumber);
Vue.use(Statistic);
Vue.use(Dropdown);
Vue.use(Slider);
Vue.use(Empty);
Vue.use(Tabs);
Vue.use(Result);
const i18n = new VueI18n({
  locale: localStorage.getItem("languageSet") || "CN", // 从localStorage里获取用户中英文选择，没有则默认中文
  messages: {
    CN: LanguageData.CN,
    EN: LanguageData.EN,
  },
});
new Vue({
  i18n,
  router: router,
  render: (h) => h(App),
}).$mount("#app");
