const info = {
  CN: {
    // 中文翻译
    contactUs: "客服联系方式",
    Ontario: "安省地区",
    BC: "B.C.地区",
    hours: "客服在线时间",
    address: "公司地址",
    partnershipinquiry: "商务合作",
    // weChatApp: "微信公众号及小程序",
    // publicAccount: "公众号二维码",
    miniapp: "小程序二维码",
    dealership: "车行入驻",
    sales: "销售合作",
    advertisement: "推广投放",
    tel: "电话",
    wechat: "微信",
    email: "邮箱",
    contact: "联系人",
    weChatApp: "客服微信二维码",
    workTime1: "周一至周五 12:00 - 18:00",
    workTime2: "周六及周天 休息",
  },
  EN: {
    // 英文翻译
    contactUs: "Contact Us",
    Ontario: "Ontario",
    BC: "B.C.",
    hours: "Hours",
    address: "Address",
    partnershipinquiry: "Partnership Inquiry",
    // weChatApp: "WeChat App",
    // publicAccount: "Public Account",
    miniapp: "Miniapp",
    dealership: "Dealership",
    sales: "Sales",
    advertisement: "Advertisement",
    tel: "Tel",
    wechat: "Wechat",
    email: "Email",
    contact: "Contact",
    weChatApp: "WeChat App",
    workTime1: "Weekdays 12:00 - 18:00",
    workTime2: "Weekends Closed",
  },
};
export default info;
