import Vue from "vue";
import LoginDialog from "./LoginDialog.vue";
// 这里可以用Vue.extend()创建子类，但是我没打算注册为组件，就直接`new Vue`
const loginDialog = new Vue(LoginDialog);
loginDialog.$mount(document.createElement("div"));
document.body.appendChild(loginDialog.$el);

Vue.prototype.$loginDialog = loginDialog;

export default loginDialog;
