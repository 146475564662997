// 主页等翻译文件的汇总，在此页汇总一个模块（如这里的user用户模块）的翻译文件并暴露出去
import ContactUs from "./contactUs";
const CN = {
  ContactUs: ContactUs.CN,
};
const EN = {
  ContactUs: ContactUs.EN,
};
export default {
  CN,
  EN,
};
