import axios from "axios";
import qs from "qs";
import dialog from "@/util/dialog";
import { ResultCodeEnum } from "@/constants";
import urls from "@/api/urls";
import config from "@/config";

// 控制台打印模拟数据
import { logMockAxios } from "../../mock/core/logMock";
// 引入mock数据
if (__MOCK__) {
  require("../../mock/core");
}

const instance = axios.create({
  baseURL: config.apiPrefix(),
  withCredentials: true,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Client: "web",
    localeLanguage: localStorage.getItem("languageSet") === "EN" ? "en" : "cn",
  },
  transformRequest: [
    function(data) {
      let ret = "";
      if (data) {
        ret = JSON.stringify(data);
        return ret;
      }
    },
  ],
});

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
instance.interceptors.response.use(
  (response) => {
    // 控制台打印模拟数据
    logMockAxios(response);

    const data = response.data;
    if (typeof data !== "object") {
      throw new Error("响应数据必须为object类型(typeof)");
    }

    if (data.code !== ResultCodeEnum.success.code) {
      // 其他错误
      dialog.message.error(data.errorMsg ? data.errorMsg : "操作失败");
    }
    return data;
  },
  (error) => {
    dialog.message.error("网络请求失败，请重试");
    return Promise.reject(error);
  }
);

export default {
  get: (url, data) => {
    return instance.get(url, data ? { params: data } : {});
  },
  post: (url, data) => {
    return instance.post(url, data);
  },
  put: (url, data) => {
    return instance.put(url, data);
  },
  delete: (url, data) => {
    return instance.delete(url, data ? { params: data } : {});
  },
  patch: (url, data) => {
    return instance.patch(url, data);
  },
};
