const info = {
  CN: {
    // 中文翻译导航栏
    homeText: "主页",
    buyCars: "我要买车",
    sellCars: "我要卖车",
    merchant: "精选商家",
    information: "购车资讯",
    contactUs: "联系我们",
    download: "下载蓝途APP",
    signUp: "登录",
    signOut: "退出登录",
    infocarAutoSale: "二手车库存",
  },
  EN: {
    // 英文翻译navbar
    homeText: "Home",
    buyCars: "Buy Cars",
    sellCars: "Sell/Trade",
    merchant: "Merchant",
    information: "Information",
    contactUs: "Contact Us",
    download: "Download Righto",
    signUp: "Sign Up",
    signOut: "Sign Out",
    infocarAutoSale: "In Store",
  },
};
export default info;
