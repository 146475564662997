<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import loginApi from "@/api/loginApi";

export default {
  data() {
    return {
      locale: {
        zh_cn: "zh-CN",
        en_us: "en-US",
      },
    };
  },
  watch: {
    $route(to, from) {
      const code = this.$route.query.code;
      if (code) {
        this.loginCode(code);
      }
    },
  },

  created: function() {
    if (!localStorage.getItem("languageSet")) {
      localStorage.setItem("languageSet", "CN");
    }
  },

  mounted: function() {},
  // 方法
  methods: {
    // 切换国际化
    switchLocale() {
      this.$i18n.locale = this.locale.en_us;
    },
    async simulateLogin(parentId) {
      const param = {
        loginName: "theyin",
        password: "123456",
      };
      const res = await loginApi.simulateLogin(param);
      if (res.code === "OK") {
        console.log("模拟登录成功");
      }
    },
    async loginCode(code) {
      const param = {
        code,
      };
      const res = await loginApi.loginCode(param);
      if (res.code === "OK") {
      } else {
        // this.$router.push("/index");
      }
      const path = this.$route.path;
      this.$router.push({ path: path, query: undefined });
      location.reload();
    },
  },
};
</script>

<style lang="css">
@import "../assets/css/reset.css";
@import "../assets/css/border.css";
@import "../assets/theme/color-dark.css";     /*深色主题*/
</style>

<style lang="less">
@import "../assets/css/common.less";
@import "../assets/css/quill.snow.css";
</style>
