import { Message, Notification } from "element-ui";

const messageOptions = { duration: 1500 };

export default {
  message: {
    success: (msg) => {
      Message.success({ ...messageOptions, message: msg });
    },
    error: (msg) => {
      Message.error({ ...messageOptions, message: msg });
    },
    warning: (msg) => {
      Message.warning({ ...messageOptions, message: msg });
    },
    info: (msg) => {
      Message.info({ ...messageOptions, message: msg });
    },
  },
};
