// 主页等翻译文件的汇总，在此页汇总一个模块（如这里的user用户模块）的翻译文件并暴露出去
import Navbar from "./navbar";
import Footer from "./footer";
const CN = {
  Navbar: Navbar.CN,
  Footer: Footer.CN,
};
const EN = {
  Navbar: Navbar.EN,
  Footer: Footer.EN,
};
export default {
  CN,
  EN,
};
